import CardTimelineClub from '~/components/Molecules/Organic/CardTimelineClub/CardTimelineClub.vue'
import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotVideoInline: () => import('~/components/Molecules/VideoInline/VideoInline.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
    },

    description: {
      type: String,
    },

    video: {
      type: Object,
    },

    cta: {
      type: Object,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      cardTimelineClub: CardTimelineClub,
      swiperOptions: {
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: false,
        slidesPerView: 1,

        breakpoints: {
          1280: {
            slidesPerView: 2.5,
          },
          320: {
            slidesPerView: 1.3,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
    }
  },
}
